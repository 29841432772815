<template>
  <v-dialog
    :value="value"
    max-width="500"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{$t('areYouSure?')}}
      </v-card-title>
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-row
          dense
          justify="space-between"
        >
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              block
              color="primary"
              large
              @click="close"
              :disabled="processing"
            >
              {{$t('cancel')}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              block
              color="error"
              large
              @click="confirm"
              :disabled="processing"
              :loading="processing"
            >
              {{$t('confirm')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: "ConfirmRemoveDialog",
	props: {
		value: {
      type: Boolean,
    },
		message: {
      type: String,
      default: ''
    },
		processing: {
      type: Boolean
    }
	},
	methods: {
		close() {
			this.$emit('closed')
		},
		confirm() {
			this.$emit('confirmed')
		}
	}
}
</script>

<style scoped>

</style>