<template>
  <v-dialog
    :value="value"
    :persistent="processing"
    max-width="800"
    @click:outside="abort"
  >
    <v-card>
      <v-card-title>{{ $t('editTeamMember') }}</v-card-title>
      <v-card-subtitle>{{ $t('configureTeamSpecificSettings') }}</v-card-subtitle>
      <v-divider />
      <v-card-text class="pt-4">
        <v-row dense>
          <v-col cols="12">
            <v-select
              :disabled="processing"
              :label="$t('role')"
              v-model="role"
              :items="roles"
              item-text="name"
              item-value="role_id"
              filled
              dense
              required
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          color="red"
          :disabled="processing"
          @click="abort"
        >
          {{ $t('abort') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="processing || tempDisabled"
          :loading="processing"
          @click="update"
        >
          {{ $t('update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: "TeamMemberEditDialog",
	props: {
		value: {
      type: Boolean
    },
		processing: {
      type: Boolean
    },
		member: {
      type: Object,
      default: () => {}
    },
		roles: {
      type: Array,
      default: () => []
    }
	},
	data() {
		return {

		}
	},
	computed: {
		tempDisabled() {
			return Object.keys(this.member).length <= 2
		},
		role: {
			get() {
				return this.member.role_id
			},
			set(value) {
				this.member.role_id = value
			}
		}
	},
	methods: {
		abort() {
			this.$emit('close')
		},
		update() {
			this.$emit('update')
		}
	}
}
</script>

<style scoped>

</style>
