<template>
  <div>
    <v-card class="mx-auto">
      <v-img
        height="200px"
        class="grey"
      />
      <v-card-title>{{ member.user.displayName }}</v-card-title>
      <v-card-subtitle>{{ memberRole }}</v-card-subtitle>
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              link
              :href="'tel:' + member.user.phoneNumber"
              :disabled="processing"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-phone</v-icon>
            </v-btn>
          </template>
          <span v-if="member.user.phoneNumber">{{ $t('call') }} {{ member.user.phoneNumber }}</span>
          <span v-else>{{ $t('noPhoneText') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              link
              :href="'mailto:' + member.user.email"
              :disabled="processing"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-message</v-icon>
            </v-btn>
          </template>
          <span v-if="member.user.email">{{ $t('mailto') }} {{ member.user.email }}</span>
          <span v-else>{{ $t('noEmailText') }}</span>
        </v-tooltip>
        <v-spacer />
        <v-btn
          text
          color="primary"
          :disabled="processing"
          :loading="updatingUser"
          @click="showEditDialog = true"
        >
          {{ $t('edit') }}
        </v-btn>
        <v-btn
          text
          target="_blank"
          :href="'https://admin.salescloud.is/team/' + member.user.uid + '/delete'"
          color="red"
          v-if="false"
        >
          {{ $t('remove') }}
        </v-btn>
        <v-btn
          text
          color="red"
          @click="showRemoveDialog = true"
          :disabled="processing"
          :loading="removingFromTeam"
        >
          {{ $t('remove') }}
        </v-btn>
      </v-card-actions>
    
      <ConfirmRemoveDialog
        v-model="showRemoveDialog"
        v-if="showRemoveDialog"
        @closed="showRemoveDialog = false"
        @confirmed="remove"
        :processing="processing"
        :message="$t('confirmRemoveFromTeam')"
      />
      
      <TeamMemberEditDialog
        v-model="showEditDialog"
        v-if="showEditDialog"
        @close="showEditDialog = false"
        @update="updateTeamMember"
        :processing="updatingUser"
        :member="member"
        :roles="roles"
      />
    </v-card> 
    <v-dialog
      v-model="noRemovingDeviceDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('cantRemoveDevice') }}
        </v-card-title>
        <v-card-text> {{ $t('certainTeamMembersRepresentDevicesAndMayNotBeRemoved') }} </v-card-text>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="noRemovingDeviceDialog = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  
import ConfirmRemoveDialog from "@/components/common/ConfirmRemoveDialog"
import TeamMemberEditDialog from "@/components/team/TeamMemberEditDialog"

export default {
  name: 'TeamMemberCard',
	components: {
		TeamMemberEditDialog,
		ConfirmRemoveDialog
	},
  props: {
    member: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
		showRemoveDialog: false,
		showEditDialog: false,
		removingFromTeam: false,
		updatingUser: false,
		noRemovingDeviceDialog: false,
	}),
	computed: {
		processing() {
			return this.removingFromTeam || this.updatingUser
		},
		memberRole() {
			if(this.roles && this.roles.length) {
				let foundRole = this.roles.find(role => role.role_id === this.member.role_id)
				if(foundRole) {
					return foundRole.name
				} else {
					if(this.member.role_id === 0) {
						return this.roles.find(role => role.role_id === 3).name
					} else {
						return this.member.role_id
					}
				}
			} else {
				return this.member.role_id
			}
		},
		roles() {
			return this.$store.state.roles
		},
		userIsDevice() {
			return this.member.user.displayName.toLowerCase().includes('device')
		}
	},
	methods: {
		updateTeamMember: function() {
			this.updatingUser = true
			// ATTN: does nothing yet because setting role has no effect yet
			this.updatingUser = false
		},
		openRemoveDialogIfNotDevice() {
			if(this.userIsDevice) {
				this.noRemovingDeviceDialog = true
			}
			else {
				this.showRemoveDialog = true
			}
		},
		remove: function() {
			this.removingFromTeam = true
			let user = this.member
			this.$store.dispatch('removeFromTeam', this.member).then(result => {
				if(result) {
					this.showRemoveDialog = false
          let message = this.$t('youHaveSuccessfullyRemovedStringFromOrganizationTeam', {string: user.user.displayName})
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: message
          })
				} else {
					this.showRemoveDialog = false
          let message = this.$t('weWereUnableToRemoveStringFromOrganizationTeam', {string: user.user.displayName})
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: message
          })
				}
			}).catch(() => {
				this.showRemoveDialog = false
				let message = this.$t('weWereUnableToRemoveStringFromOrganizationTeam', {string: user.user.displayName})
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: message
        })
			}).finally(() => {
				this.removingFromTeam = false
			})
		},
	}
}
</script>


